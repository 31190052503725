@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply bg-gray-900;
    }

    li {
        @apply cursor-pointer p-4 hover:text-licom-blue;
    }
}

.active {
    @apply text-licom-blue;
}